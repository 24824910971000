import moment from 'moment-timezone';

function getFirstAndLastDate(previousMonth = 0) {
  if (previousMonth < 0) {
    previousMonth = 0;
  }

  const today = moment.tz('Europe/London');
  const startDate = today.clone().subtract(previousMonth, 'months').startOf('month');
  const endDate = today.clone().subtract(previousMonth, 'months').endOf('month');

  const startDateStr = startDate.format('YYYY-MM-DDT00:00:00');
  const endDateStr = endDate.set({ hour: 23, minute: 59, second: 59 }).format('YYYY-MM-DDTHH:mm:ss');

  return { from: startDateStr, to: endDateStr };
}

export default getFirstAndLastDate;
