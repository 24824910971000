import moment from 'moment-timezone';

function getSearchDatesRange(from, to) {
  if (!to || to === 0) {
    to = from;
  }
  const date = new Date().toISOString();
  const rawDate = moment(date);

  const nextSevenDays = moment(rawDate)
    .add(to, 'days')
    .tz('Europe/London')
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm:ss');

  const lastSevenDays = moment(rawDate)
    .subtract(from, 'days')
    .tz('Europe/London')
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm:ss');

  const now = moment();
  // Go to the 1st day of the previous month
  const firstDayOfPreviousMonth = now.clone().subtract(1, 'months').startOf('month');
  const lastDayOfCurrentMonth = now.clone().endOf('month');

  const firstOfPrevMonth = firstDayOfPreviousMonth.startOf('month').format('YYYY-MM-DDT00:00:00');
  const lastOfCurrentMonth = lastDayOfCurrentMonth.endOf('month').set().format('YYYY-MM-DDT23:59:59');

  return { from: lastSevenDays, to: nextSevenDays, firstOfPrevMonth, lastOfCurrentMonth };
}

export default getSearchDatesRange;

export function getTransactionDatesRange() {
  const lastDates = new Date();
  const lastDays = lastDates.setDate(lastDates.getDate() - 1);
  const lastSevenDays = new Date(lastDays).toISOString().replace('Z', '');
  const nextDates = new Date();
  const nextDays = nextDates.setDate(nextDates.getDate() + 0);
  const nextSevenDays = new Date(nextDays).toISOString().replace('Z', '');
  return { from: lastSevenDays, to: nextSevenDays };
}
